:root {
   --header-height: 50px;
   --bottom-menu-height: 10vh;
   --input-area-height: 60px;
   --primary-color: var(--theme-primary-color);
   --secondary-color: var(--theme-secondary-color);
   --background-color: var(--theme-background-color);
   --text-color: var(--theme-text-color);
   --light-text-color: var(--theme-light-text-color);
   --light-gray-color: var(--theme-light-gray-color);
   --very-light-gray-color: var(--theme-very-light-gray-color);
   --medium-gray-color: var(--theme-medium-gray-color);
 }

.App {
   display: flex;
   flex-direction: column;
   height: 100vh; /* Fallback */
   height: calc(var(--vh, 1vh) * 100);
   font-family: var(--theme-font-main);
   /* text-align: center; */
   background-color: var(--background-color);
   margin: 0;
   width: 100%;
   top: 0;
   left: 0;
   
 }

.App-header {
  height: var(--header-height);
  background-color: var(--primary-color);
  color: var(--light-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
}

.App-header h1 {
  font-size: clamp(1rem, 3vw, 1.3rem);
  margin: 0;
}

.landing-page-container {
  height: auto;
  overflow-y: auto;
  position: relative;
  overflow-x: auto;
}

.content-area {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  min-height: calc(100vh - var(--header-height));
  transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
  padding: 0;
}

.history-button,
.restart-button {
  background: none;
  border: none;
  color: var(--secondary-color);
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20px;
  height: 15px;
}

.menu-icon span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: currentColor;
}

.chat-discussion-mobile {
  position: fixed;
  top: var(--header-height);
  left: 0;
  width: 100%;
  height: calc(100% - var(--header-height));
  background-color: var(--background-color);
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.chat-discussion-mobile.open {
  transform: translateX(0);
}

.chat-menu-band {
  position: sticky;
  top: var(--header-height);
  height: 50px;
  background-color: var(--very-light-gray-color);
  display: flex;
  align-items: center;
  padding: 0 10px;
  z-index: 999;
}



@media (max-width: 767px) {
  .App-header {
    display: flex;
  }

  .content-area {
    padding-top: 0;
    height: calc(100vh - var(--header-height) - var(--bottom-menu-height));
    background-color: var(--background-color);
  }

  .content-area.mobile {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    background-color: var(--background-color);
  }

  .chat-discussion-mobile {
    box-shadow: none;
  }

  .chat-menu-band {
    box-shadow: none;
    background-color: var(--background-color);
  }
}


@media (min-width: 768px) {
  :root {
    --header-height: 60px;
  }

  .App-header {
    height: var(--header-height);
  }

  .content-area {
    height: calc(100vh - var(--header-height));
  }
}